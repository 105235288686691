<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Journey Users</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="tab_wpr mt-3">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="journey-user-per-page-filter" />
                                </li>
                                <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search"/>
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ journeyUsers.from ? journeyUsers.from : 0 }} - {{ journeyUsers.to ? journeyUsers.to : 0 }} of <span>{{ journeyUsers.total ? journeyUsers.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="journeyUsersLoader"><line-loader /></div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th v-if="journey.is_new">Status</th>
                                    <th>Last Triggered</th>
                                </tr>
                            </thead>
                            <tbody v-if="journeyUsers.data && journeyUsers.data.length">
                                <tr v-for="(journeyUser, m) in journeyUsers.data" :key="m">
                                    <td>
                                        <div class="user_wpr" @click="selectedContact = journeyUser.contact; profile = true">
                                            <h4>{{ journeyUser.contact && journeyUser.contact.name ? journeyUser.contact.name : '' }} <span v-if="journeyUser.contact && journeyUser.contact.isDelete" class="text-danger">[deleted]</span></h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">{{  journeyUser.contact && journeyUser.contact.email ? journeyUser.contact.email : ''}}</div>
                                    </td>
                                    <td v-if="journey.is_new">
                                        #{{ journeyUser.delay ? journeyUser.delay.ref + 1 : 0  }}
                                        <span v-if="journeyUser.delay && journeyUser.delay.is_terminated" class="text-danger">[Terminated]</span>
                                    </td>
                                    <td>{{ moment.utc(journeyUser.date_created).local().format('ll | LT') }}</td>
                                </tr>
                            </tbody>
                            <tbody class="no_record" v-else>
                                <tr>
                                    <td colspan="3" class="px-4">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4" v-if="journeyUsers.data && journeyUsers.data.length">
                        <pagination v-model="params.page" :pages="journeyUsers.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
    <profile-component v-model="profile" :contact="selectedContact" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex';

    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))

    import moment from 'moment'

    export default {
        data () {
            return {
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    journey_id: '',
                },
                isTyping: false,
                profile: false,
                selectedContact: {},
                moment,
            }
        },

        props: {
            modelValue: Boolean,
            journey: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.resetParams();

                    vm.getJourneyUsers(vm.params);
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getJourneyUsers(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500)
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.params.page = 1;

                            vm.getJourneyUsers(vm.params);
                        }
                    }
                }
            },
        },

        components: {
            ProfileComponent,
        },

        computed: mapState({
            journeyUsersLoader: state => state.journeyModule.journeyUsersLoader,
            journeyUsers: state => state.journeyModule.journeyUsers,
        }),

        methods: {
            ...mapActions({
                getJourneyUsers: 'journeyModule/getJourneyUsers',
                deleteJourneyUser: 'journeyModule/deleteJourneyUser',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['journey-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['journey-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getJourneyUsers(vm.params);
            },

            resetParams () {
                const vm = this;

                vm.params = {
                    per_page: 5,
                    page: 1,
                    search: '',
                    journey_id: vm.journey.id,
                };
            },
        }
    }
</script>

<style  scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    }

    .result_wpr table.standard tbody.no_record td:first-child {
        width: auto;
        text-align: center;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .action_list li i.fa-trash-alt{
        color: #eb1414;
    }
</style>
